// 去掉左右空白字符
export function trim(s){
    return s.replace(/(^\s*)|(\s*$)/g, "")
}
export function tansParams(params) {
    let result = ''
    for (const propName of Object.keys(params)) {
        const value = params[propName];
        var part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof (value) !== "undefined") {
            if (typeof value === 'object') {
                for (const key of Object.keys(value)) {
                    if (value[key] !== null && typeof (value[key]) !== 'undefined') {
                        let params = propName + '[' + key + ']';
                        var subPart = encodeURIComponent(params) + "=";
                        result += subPart + encodeURIComponent(value[key]) + "&";
                    }
                }
            } else {
                result += part + encodeURIComponent(value) + "&";
            }
        }
    }
    return result
}
export function getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    // $.mobileConsole(r);
    if (r != null) {
        var value = r[2];
        value = value.replace(/(%22|%3E|%3C|<|>)/g, "MM");
        if (value == "") {
            //此处为安卓下浏览器的一个坑，匹配正则有区别
            return null;
        } else {
            return decodeURIComponent(value);
        }
    } else {
        return null;
    }
}
export function isEnv() {
    if (
        window.location.origin.indexOf('imvib.com') > -1 ||
        window.location.origin.indexOf('imd168.com') > -1 ||
        window.location.origin.indexOf('51imd.com') > -1 ||
        window.location.origin.indexOf('ilcmv.com') > -1 ||
        window.location.origin.indexOf('ib668.com') > -1
    ) {
        return 1;
    } else {
        return 0;
    }
}
const host = window.location.origin
export default host