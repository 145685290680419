import { useState } from 'react'
import { Tabs } from 'antd'
import icon_left from '../../assets/header/icon_left.png'
import icon_right from '../../assets/header/icon_right.png'
import icon_blockOne_1 from '../../assets/icon_blockOne_1.svg'
import icon_blockOne_2 from '../../assets/icon_blockOne_2.svg'
import icon_blockOne_3 from '../../assets/icon_blockOne_3.svg'
import icon_blockOne_4 from '../../assets/icon_blockOne_4.svg'
import icon_blockOne_5 from '../../assets/icon_blockOne_5.svg'
import icon_01 from '../../assets/icon_01.png'
import icon_02 from '../../assets/icon_02.png'
import icon_03 from '../../assets/icon_03.png'
import icon_04 from '../../assets/icon_04.png'
import icon_05 from '../../assets/icon_05.png'
import pc_2 from '../../assets/header/pc_2.png'
import img_1 from '../../assets/header/img_1.png'
import img_2 from '../../assets/header/img_2.png'
import img_3 from '../../assets/header/img_3.png'
import img_4 from '../../assets/header/img_4.png'
import four_1 from '../../assets/header/four_1.svg'
import four_2 from '../../assets/header/four_2.svg'
import four_3 from '../../assets/header/four_3.svg'
import four_4 from '../../assets/header/four_4.svg'
import block_img_4 from '../../assets/header/block_4.png'

import './version_1.less'
const TabPane = Tabs.TabPane
const slick = [
    'https://data.wedongmi.com/xinpi/official_website/png/banner.png',
]
const slick_two = [
    {sName: '公告复核', sImg: pc_2, sImgM: "https://data.wedongmi.com/xinpi/official_website/png/icon_review.png", sContent: "是一款全新的上市公司公告智能检测客户端。<br />财务勾稽关系校验、错别字校验、标点与单位错误检查、其他易错点检查", sId: '0'   
    },
    {sName: '股东名册', sImg: pc_2, sImgM: "https://data.wedongmi.com/xinpi/official_website/png/icon_sh.png", sContent: "一键生成股东名册分析报告；追踪某一股东的持股变动；分析机构持股变化情况及信用股东情况。", sId: '1'   
    }
]
const block_1 = [
    { 
        sTitle: '合规与监管', 
        sImg: icon_blockOne_1,
        list: [
            [
                { sName: '法律法规', sContent: '法规精准检索，法规数据全面，分类清晰。法规条款与违规案例相结合，结合实践理解法规，经典避坑指南' },
                { sName: '违规案例', sContent: '违规案例收集了全市场2014年至今所有的违规案例数据。系统对违规案例进行了专业的分类处理，用户可一键检索全市场、关注公司及公司案例等各类违规案例信息' },
                { sName: '问询函件', sContent: '问询函件收集了全市场的监管问询信息。系统对函件类型、问题类别进行了专业的分类，问询类型覆盖交易所、证监会等多个监管方，用户可快速检索全市场及关注公司的各类问询信息' },
                { sName: '监管动态', sContent: '监管动态涵盖上交所、深交所、北交所、证监会、证券业协会、中国结算等官方网站最新的监管动态，每天定时看一看，监管一线信息全部获悉，了然于心' }
            ]
        ]
    },
    { 
        sTitle: '信息披露', 
        sImg: icon_blockOne_2,
        list: [
            [
                { sName: '公告检索', sContent: '公告检索模块收集了全市场所有的公告数据。系统对公告数据进行了专业的分类处理，用户可以一键检索全市场的各类已披露公告。多维度考虑用户需求，公告检索功能支持用户快速检索全市场及关注公司发布的全部公告' },
                { sName: '信息披露', sContent: '信息披露集合了所有的预披露和已披露公告，便于用户管理所有对外信息披露工作。公告披露类型覆盖全面，提供完整的信披文件、披露要点、报批材料、相关的法律法规及专员的经验总结等数据供用户参考，是信披的万能百宝箱' },
                { sName: '股份增减持', sContent: '可实时计算董监高及大股东的增减持合规情况。窗口期、内幕人、增减持承诺、减持披露均有提醒，从此告别违规增减持。' },
                { sName: '交易与关联交易', sContent: '权限速查表提供了详尽的重大事项披露指标的判定值，如交易、关联交易、提供担保等8个大类，根据公司财报数据，自动计算判定值，便捷精准。并且提供信披搜一搜功能，可自定义检索特定事项的判定值，简单好用' },
                { sName: '减持新规', sContent: '减持新规受限查询，受限指标展示，快速定位该公司是否受减持新规限制；减持新规受限统计，包括板块、减持原因、公司市值' }
            ]
        ]
    },
    { 
        sTitle: '投资者关系', 
        sImg: icon_blockOne_3,
        list: [
            [
                { sName: '互动问答', sContent: '互动问答统计市场、同行业及公司的提问数、回答数及回复率。自由检索问答数据，智能提供同类型问题参考答案，各种刁钻问题，轻松应对。也可设置有新问题时第一时间提醒，问答超时提醒，不用持续盯站；投资者互动平台，一键直达。' },
                { sName: '视频营销', sContent: '营销视频，每天会生成公司的行情和财报视频，便于上市公司对外营销推广。其中包括行情回顾和可视化财报等相关视频。' },
                { sName: '投关数据库', sContent: '投关数据库模块收录了分析师、基金经理以及保荐代表人等的详细信息以及机构信息，供用户查阅。还收集了全市场的机构调研 (投资者关系活动)、研报数据' },
            ]
        ]
    },
    { 
        sTitle: '合规治理', 
        sImg: icon_blockOne_4,
        list: [
            [
                { sName: '电子签名', sContent: '支持股东大会、董事会、股权激励协议等多种场景的文件签署线上化。全流程线上签名，支持短信/邮件链接H5方式签署，随时随地签名更高效。电子签名与手写签名具有同等法律效力。' },
                { sName: '会议管理', sContent: '会议管理功能，可对三会进行管理，会议信息与具体事项相关联，近期会议情况，轻松掌握。可一键生成即将召开的会议的会议资料，会议准备，一键完成。还有会议统计和会议归档功能。' },
                { sName: '文件管理', sContent: '公司文件集成管理，可上传自己的文件，可在系统上传共享文件，内部流传，方便快捷。' },
                { sName: '事项管理', sContent: '提供常见事项流程以及事项时间，合规性要求，记录事项实时进度，方便复盘总结。也提供事项统计，对公司事项进度一目了然。' }
            ]
        ]
    },
    { 
        sTitle: '其他介绍', 
        sImg: icon_blockOne_5,
        list: [
            [
                { sName: '异动预警', sContent: '实时监控股价异动关键指标，关键指标根据业务规则分为深主板、创业板、沪主板、科创板。股价波动一旦触发预警值、报警值或被认定为股价异常波动情形的，系统将第一时间通知用户' },
                { sName: '行情数据', sContent: '行情数据可查看行业行情数据，涨跌图及热门板块行业，也可关注公司盘面信息，实时动态轻松掌握' },
                { sName: '财务可视化', sContent: '财务可视化能够以更加直观、清晰、易于理解的方式展示财务数据，帮助企业进行精准预测和决策。查看公司综合排名、数据指标排名、分析指标等数据表现。查看对比公司的报表、财务指标的数据情况。' },
                { sName: '资本运作', sContent: '可查看市场上配股、增发、配套融资、各类债券的发行情况。回购、股权质押，为上市公司第一时间呈现近期的股权运作情况。可查看近期市场上并购重组的进展。可查看IPO机构、非公开发行机构和资产重组机构的运作情况。' }
            ]
        ]
    }
]
const block_line = [
    { sName: '监管有据可查', sContent: '上市公司法规全覆盖，及时更新，深度解读。汇集问询信息，协助掌控市场。', sImg: img_1},
    { sName: '信披统一管理', sContent: '集合所有信披任务，管理便捷，操作安全。您的贴心信披顾问，协助判断披露信息。', sImg: img_2},
    { sName: '三会全面掌握', sContent: '全流程会议大师，智能推进会议。量身打造会议任务，高效的三会管理。', sImg: img_3},
    { sName: '财报直观展示', sContent: '定期报告数据以图表形式可视化展示。支持自定义设置图表、支持财报扫码分享', sImg: img_4}
]
const block_4 = [
    { sName: '速度快  成本低  风险小', sContent: '一键生成股东名册分析报告，本地安装客户端，支持断网操作', sImg: four_1},
    { sName: '法规全  数据精  功能好', sContent: '精准严格的法规法条拆解，为企业合规提供高质量数据', sImg: four_2},
    { sName: '公告广  强辅助  体验好', sContent: '覆盖三市全网公告，一键调取所需。完善的公告披露指引，为合规信披保驾护航', sImg: four_3},
    { sName: '选择多  可信赖  体验好', sContent: '产品根据不同需求为客户提供web端、PC端等个性化平台，更加方便高效地为客户提供服务', sImg: four_4}
]
function Version_1(props) {
    const { onLogin } = props
    const [lineActive, setLineActive] = useState(0)
    const [twoActive, setTwoActive] = useState('0')
    const [tabKey, setTabKey] = useState(0)
    const [tabContentKey, setContentKey] = useState(0)
    return (
        <main className="version_1">
            <div className="block-banner">
                <div className="block-content">
                    <div className="banner-back">
                        
                    </div>
                </div>
                <div className="login-content">
                    <div className="tip">
                        <div className="bigTitle">合规/安全/高效/智能</div>
                        <div className="smallTitle">致力提升董办工作效率</div>
                        <div onClick={()=>onLogin(2)} className="try">去体验</div>
                    </div>
                    {/* <div className="left-info">
                        <img src={icon_main}/>
                        <div className="left-info-detail">
                            <img className="left-img-left" src={icon_cycle_1}/>
                            <img className="left-img-right" src={icon_cycle_2}/>
                            <img className="left-left-1" src={icon_left_1}/>
                            <img className="left-left-2" src={icon_left_2}/>
                            <img className="left-right-1" src={icon_right_1}/>
                            <img className="left-right-2" src={icon_right_2}/>
                            <div className="left-info-detail-list">
                                {
                                    [
                                        { padding: '24px 32px 0 32px', sImg: icon_main_1, background: '#1D6BF4', sContent: '数字化Saas产品，合规信息化数据服务，高效提升董办工作效率'}, 
                                        { padding: '24px 32px 0 32px', sImg: icon_main_2, background: '#003AA6', sContent: '业务领域延伸至合规信披、财经公关&投资者关系、研究驱动型深度投资者关系顾问咨询、ESG业务、半导体投融资并购等，为董办产品SaaS化提供全面的业务支持'}, 
                                        { padding: '16px 16px 0 16px', sImg: icon_main_3, background: '#00ABC7', sContent: '可视化数据用简短的图形就能体现复杂信息，甚至单个图形也能做到，在工作中更加方便地对数据了解和使用'}, 
                                        { padding: '16px 16px 0 16px', sImg: icon_main_4, background: '#4B4AFF', sContent: '多维度处理数据，满足董办多场景多需求，为董办工作合规高效打下坚实基础'}, 
                                        { padding: '16px 16px 0 16px',sImg: icon_main_5, background: '#089EEA', sContent: '根据用户预先设置的预警条件，智能判别数据，并将符合条件的信息，第一时间通过多渠道告知用户，方便用户及时知晓'}].map((item, i)=>
                                    <div className="left-info-detail-item" key={i}>
                                        <img src={item.sImg}/>
                                        <div style={{background: item.background}}>
                                            <div style={{padding: item.padding}}>{item.sContent}</div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="left-info-title">市值管理数智化终端</div>
                        <div className="left-info-content">
                            <div className="left-info-item">
                                <div className="info-item">
                                    <div className="info-item-title">
                                        <img src={top_1}/>
                                        <div>工作高效便捷</div>
                                    </div>
                                    <div className="info-item-content">致力于为上市公司提供合规信息化SaaS产品及大数据服务，助力董办合规工作互联网+，提升工作效率</div>
                                </div>
                                <div className="info-item">
                                    <div className="info-item-title">
                                        <img src={top_5}/>
                                        <div>资深业务支持</div>
                                    </div>
                                    <div className="info-item-content">业务领域延伸至合规信披、财经公关&投资者关系、研究驱动型深度投资者关系顾问咨询、ESG业务、半导体投融资并购等，为董办产品SaaS化提供全面的业务支持</div>
                                </div>
                            </div>
                            <div className="left-info-item">
                                <div className="info-left">
                                    <div className="info-item">
                                        <div className="info-item-title">
                                            <img src={top_2}/>
                                        </div>
                                        <div className="info-item-content">
                                            <div>数据展示直观</div>
                                            可视化数据能够用一些简短的图形就能体现那些复杂信息，甚至单个图形也能做到，在工作中更加方便地对数据了解和使用
                                        </div>
                                    </div>
                                    <div className="info-item">
                                        <div className="info-item-title">
                                            <img src={top_3}/>
                                            
                                        </div>
                                        <div className="info-item-content">
                                            <div>数据处理多维度</div>
                                            多维度处理数据，满足董办多场景多需求，为董办工作合规高效打下坚实基础
                                        </div>
                                    </div>
                                
                                </div>
                                <div className="info-right">
                                    <div className="info-item">
                                        <div className="info-item-title">
                                            <img src={top_4}/>
                                            <div>多场景智能预警</div>
                                        </div>
                                        <div className="info-item-content">根据用户预先设置的预警条件，智能判别数据，并将符合条件的信息，第一时间通过多渠道告知用户，方便用户及时知晓</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="right-login">
                        <Login />
                    </div> */}
                </div>
            </div>
            <div className="block-one">
                <div className="block-content">
                    <div className="title">产品介绍</div>
                    <div className="tip">数字化Saas产品，合规信息化数据服务，高效提升董办工作效率</div>
                    <div className="tabs">
                        <div className="tabs-nav">
                            <div className="tabs-item">
                                {
                                    block_1.map((item, i) => 
                                    <div onClick={()=>{setTabKey(i);setContentKey(0)}} key={i} className="tabItem">
                                        <img src={item.sImg}/>
                                        <div>{item.sTitle}</div>
                                    </div>
                                )}
                            </div>
                            <div style={{left: tabKey * 20 + '%'}} className="tabs-active"></div>
                        </div>
                        <div className="tabs-content">
                            {
                                block_1[tabKey].list[0].map((item, i) => 
                                <div onMouseOver={()=>setContentKey(i)}  key={i} className={`tabContent ${tabContentKey === i ? 'tabContentActive' : ''}`}>
                                    <div className='backimg'>
                                        
                                    </div>
                                    <div className="tabSpace">
                                        <div className="contentNum"><img src={[icon_01, icon_02, icon_03, icon_04, icon_05][i]}/></div>
                                        <div className="name">{item.sName}</div>
                                        <div className="content">{item.sContent}</div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* <Tabs tabBarGutter={40} size="large">
                            {
                                block_1.map((item, i)=> 
                                <TabPane tab={item.sTitle} key={i}>
                                    <Carousel {...slickSettings}>
                                            {
                                                item.list.map((ele, index) =>
                                                <div key={index}>
                                                    <div className="tab-content">
                                                        {
                                                            ele.map((ele, index)=>
                                                            <div key={index} className="tab-content-item">
                                                                <img src={icons}/>
                                                                <div className="name">{ele.sName}</div>
                                                                <div className="content">{ele.sContent}</div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                    </Carousel>
                                </TabPane>
                            )}
                        </Tabs> */}
                    </div>
                </div>
            </div>
            <div className="block-two">
                <div className="block-content">
                    <Tabs tabBarGutter={0} onChange={(value)=>setTwoActive(value)} activeKey={twoActive} size="large">
                        {
                            slick_two.map((item, i)=> 
                            <TabPane tab={item.sName} key={item.sId}>
                                <div className="tab-content">
                                    <div className="carousel" key={i}>
                                        <div  className="carouselItem">
                                            <div>
                                                <img onClick={()=>setTwoActive('0')} src={icon_left}/>
                                                <div className="spaceLeft"></div>
                                                <div className="left"><img alt=""className="banner-img"src={item.sImgM}/></div>
                                                <div className="right">
                                                    <img
                                                        alt=""
                                                        className="banner-img"
                                                        src={item.sImg}
                                                    />
                                                    <div className="name">{item.sName}</div>
                                                    <div className="content" dangerouslySetInnerHTML={{__html: item.sContent}}></div>
                                                </div>
                                                <div className="spaceRight"></div>
                                                <img onClick={()=>setTwoActive('1')} src={icon_right}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                        )}
                    </Tabs>
                </div>
            </div>
            <div className="block-three">
                <div className="block-content">
                    <div className="title">董蜜特色功能</div>
                    <div className="line">
                        {
                            block_line.map((item, i)=>
                            <div key={i} onClick={()=>setLineActive(i)} className="line-item">
                                <div className={'name ' + (lineActive === i ? 'active': '')}>{item.sName}</div>
                                <div className="line-dot">
                                    <div style={i !== 0 ? {} : {background: '#fff'}} className="line-line-item"></div>
                                    <div className={'line-dot-item '+(lineActive === i ? 'active-dot': '')}><div></div></div>
                                    <div style={i !== block_line.length - 1 ? {} : {background: '#fff'}} className="line-line-item"></div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="tip">{block_line[lineActive].sContent}</div>
                    <div className="img"><img src={block_line[lineActive].sImg}/></div>
                </div>
            </div>
            <div className="block-four">
                <div className="block-content">
                    <div className="title">技术创新</div>
                    <div className="tip">致力于为上市公司、非上市公司提供专业、安全、合规的信息化SaaS产品及合规诚信大数据服务</div>
                    <div className="list">
                        {
                            block_4.map((item, i)=>
                            <div className="list-item" key={i}>
                                <img src={item.sImg}/>
                                <div className="list-item-content">
                                    <div>{item.sName}</div>
                                    <div>{item.sContent}</div>
                                </div>
                            </div>
                        )}
                    </div>
                    <img className="img" src={block_img_4}/>
                </div>
            </div>
        </main>
    )
}
export default Version_1