import { useState, useRef, useEffect } from 'react'
import './loginPage.less'
import {
    Button,
    Form,
    Input,
    message,
    Spin,
} from 'antd'
import icon_code from '../../assets/banner/icon_code.png'
import icon_back from '../../assets/login/icon_back.svg'
import { getQRCode, checkQRCode } from '../../utils/api'
import { applyForRial } from '../../utils/apiC'
import common from '../../utils/common'
import { SyncOutlined, LeftOutlined } from '@ant-design/icons'
import Login from './login'
function LoginPage(props) {
    const timerID = useRef()
    const [form] = Form.useForm()
    const { onLogin } = props
    const [tryuseVisible, setTryuseVisible] = useState(false)
    const [arcodeFailure, setArcodeFailure] = useState(false)
    const [loading, setLoading] = useState(true)
    const [modalType, setModalType] = useState(1)
    const [arcodeImg, setArcodeImg] = useState('')

    const _getQRCode = () => {
        setLoading(false)
        clearInterval(timerID.current)
        setLoading(true)
        const data = {}
        getQRCode(data).then(res => {
            setLoading(false)
            if (res) {
                const content = res
                if (content.vQRData.length) {
                    // const array = new Uint8Array(content.vQRData)
                    // const blob = new Blob([array], {
                    //     type: 'application/octet-binary'
                    // })
                    // let oFileReader = new FileReader()
                    // oFileReader.readAsDataURL(blob, 'image/png')
                    // oFileReader.onloadend = e => {
                    //     setArcodeImg(e.target.result)
                    //     setArcodeFailure(false);
                    // }
                    setArcodeImg('data:image/png;base64,' + content.vQRData)
                    setArcodeFailure(false)
                    timerID.current = setInterval(() => {
                        const date = Date.parse(new Date())
                        if (content.iExpireTime < date) {
                            setArcodeFailure(true)
                            clearInterval(timerID.current)
                        } else {
                            _checkQRCode({ sUuid: content.sUuid })
                        }
                    }, 1000)
                }
            }
        })
    }

    const _checkQRCode = (params, value) => {
        const paramsData = { ...params }
        checkQRCode(paramsData).then(res => {
            if (res) {
                const _data = res
                if (_data.iStatus === 0) {
                    clearInterval(timerID.current)
                    localStorage.setItem(
                        'rongzheng_dongmi_token',
                        JSON.stringify(res.stIdentyInformation.stXPUserInfo)
                    )
                    // if(getQueryString("next_url")) {
                    //     window.location.href = getQueryString("next_url")
                    //     return
                    // }
                    window.location.href = common + '/dongmi/'
                }
            }
        })
    }

    const handleTryuseCancel = () => {
        setTryuseVisible(false)
        form.setFieldsValue({
            sCompany: '',
            sUserName: '',
            sTitle: '',
            sPhone: '',
            sMail: ''
        })
    }

    const onTryuseFinish = fieldsValue => {
        const data = {
            ...fieldsValue,
            iProductType: modalType
        }
        applyForRial(data).then(response => {
            const res = response.data
            if (res) {
                handleTryuseCancel()
                message.success('提交成功')
            }
        })
    }
    const onTryuseFinishFailed = () => {}

    useEffect(() => {
        _getQRCode()
        return () => {
            // 组件销毁时，清除定时器
            clearInterval(timerID.current)
        }
    }, [])

    return (
        <main className="dongmi">
            <div onClick={()=>onLogin(1)} className='goBack'><img src={icon_back}/>返回官网</div>
            <div className="contentMain">
                <div className="content">
                    <div className="titbox">
                        <img src="https://idtcdn-1309770014.oss-cn-shanghai.aliyuncs.com/rz/office/login_logo.png" alt='logo'/>
                        <span>/</span>
                        <p>致力提升董办工作效率</p>
                    </div>
                    {tryuseVisible ? (
                        <div className="tryuse">
                                <div
                                    className="tryuse-back"
                                    onClick={handleTryuseCancel}
                                >
                                    <LeftOutlined /> 返回
                                </div>
                              <h2>试用申请</h2>
                            <div className="tryuse-content">
                                <Form
                                    form={form}
                                    onFinish={onTryuseFinish}
                                    onFinishFailed={onTryuseFinishFailed}
                                >
                                    <Form.Item
                                        name="sCompany"
                                        rules={[
                                            {
                                                required: true,
                                                message: `请输入公司名称!`
                                            }
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder={`请输入公司名称`}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="sUserName"
                                        rules={[
                                            {
                                                required: true,
                                                message: '请输入姓名!'
                                            }
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="请输入姓名"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="sTitle"
                                        rules={[
                                            {
                                                required: true,
                                                message: '请输入职位!'
                                            }
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="请输入职位"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="sPhone"
                                        rules={[
                                            {
                                                required: true,
                                                message: '请输入联系电话!'
                                            }
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="请输入联系电话"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        name="sMail"
                                        rules={[
                                            {
                                                required: true,
                                                message: '请输入邮箱!'
                                            }
                                        ]}
                                    >
                                        <Input
                                            size="large"
                                            placeholder="请输入邮箱"
                                        />
                                    </Form.Item>

                                    <Form.Item>
                                        <Button
                                            size="large"
                                            type="primary"
                                            htmlType="submit"
                                            className="login-form-button"
                                        >
                                            提交申请
                                        </Button>
                                    </Form.Item>
                                </Form>
                                <div className="tryuse-desc">
                                    审核通过后，我们将为您开通试用账号
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="wrap">
                            <div className="left">
                                <h3>
                                    请使用<span>董蜜APP</span>扫码登录
                                </h3>
                                <p>
                                    打开<span>董蜜APP</span> -
                                    点击搜索框右侧相机 - 点击扫码
                                </p>
                                <div>
                                    <div className="arcode">
                                        {arcodeFailure ? (
                                            <div className="failure">
                                                {loading ? (
                                                    <div className="spin">
                                                        <Spin />
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <img
                                                            src={arcodeImg}
                                                            alt=""
                                                        />
                                                        <div>
                                                            <p>二维码已失效</p>
                                                            <p
                                                                onClick={
                                                                    _getQRCode
                                                                }
                                                            >
                                                                <SyncOutlined />
                                                                刷新
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="saoma">
                                                <div>
                                                    <img
                                                        src={arcodeImg}
                                                        alt=""
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {/* <div className="code_tip" style={{marginTop:20, fontSize: 16}}><img style={{marginRight: 2}} width="20" src={icon_code}/>请打开董蜜APP扫描二维码登录</div> */}
                                    </div>
                                    <div
                                        className="code_tip"
                                        style={{
                                            marginTop: 10,
                                            fontSize: 14,
                                            color: '#86909C'
                                        }}
                                    >
                                        <img
                                            style={{ marginRight: 2 }}
                                            width="20"
                                            src={icon_code}
                                            alt=''
                                        />
                                        请打开董蜜APP扫描二维码登录
                                    </div>
                                </div>
                            </div>
                            <div className="right">
                                <Login
                                    onChange={value => setTryuseVisible(value)}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* <div className="footer">
                <span>
                    <img src={"https://idtcdn-1309770014.oss-cn-shanghai.aliyuncs.com/rz/office/%E6%B2%AA%E5%85%AC%E7%BD%91%E5%AE%89%E5%A4%87.png"} />
                    <a 
                        target="_blank" 
                        href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010502006619"
                    >
                        沪公网安备 31010502006619号
                    </a>
                </span>
                |
                <span>
                    <a 
                        target="_blank" 
                        href="https://beian.miit.gov.cn/#/Integrated/index"
                    >
                        { window.location.origin.indexOf("ib668.com") > -1 || window.location.origin.indexOf("www.ib668.com") > -1 ? "沪ICP备2023002375号-3 " : "沪ICP备09008594号-3" }
                    </a>
                </span>
                <span>Copyright © {moment().format("YYYY")} 上海荣正企业咨询服务（集团）股份有限公司 All rights reserved</span>
            </div> */}
        </main>
    )
}
export default LoginPage