import { useState } from 'react'
import { Popover } from 'antd'
import './version_2.less'
const block_5 = [
    { sImg: "https://data.wedongmi.com/xinpi/official_website/png/phone_1.png", data : [
        {
            sName: '多种核心功能', data: [
                {sContent: '集法规/违规/函件/监管/公告于一体'},
                {sContent: '事项管理/权限速查/信披速查/ESOP计算等快捷工具'},
                {sContent: '市场行情，公司行情数据一应俱全'}
            ]
        }
    ]},
    { sImg: "https://data.wedongmi.com/xinpi/official_website/png/phone_2.png", data : [
        {
            sName: '法律法规', data: [
                {sContent: '法规精准检索，法规数据全面，分类清晰'}
            ]
        },
        {
            sName: '公告大全', data: [
                {sContent: '利用数据采集平台，实时抓取全市场股票的公告数据'}
            ]
        }
    ]},
    { sImg: "https://data.wedongmi.com/xinpi/official_website/png/phone_3.png", data : [
        {
            sName: '电子签名', data: [
                {sContent: '支持股东大会、董事会、股权激励协议等多种场景的文件签署线上化。'},
                {sContent: '高效便捷/安全可靠/可信度高'}
            ]
        }
    ]},
    { sImg: "https://data.wedongmi.com/xinpi/official_website/png/phone_4.png", data : [
        {
            sName: '信披速查', data: [
                {sContent: '用户输入数据指标，系统判断是否应该披露，并给出<br/>判断依据，若需披露给出建议公<br/>告类别及最新同类公告。'}
            ]
        },
        {
            sName: '权限速查', data: [
                {sContent: '基于最近一年年度报告经审计的财务数据测算各交易<br/>事项指标及指标判定值。'}
            ]
        }
    ]},
]
function Version_2() {
    return (
        <main className="version_2">
            <div className="block-banner">
                <div className="block-content">
                    <div className="banner-back">
                        
                    </div>
                </div>
                <div className="login-content">
                    <div className="left-info">
                        <div className="left-info-title">董蜜移动端</div>
                        <div className="left-info-title-small">随时随地提升董办工作效率</div>
                        <div className="left-info-title-small">合规/安全/高效/智能</div>
                        <Popover placement="bottom" title={null} trigger="click" content={<div className='popover'><img src={"https://data.wedongmi.com/rz/app/download/dongmi-100020110-publish.apk.svg"}/> <div>扫码下载</div></div>}><div className="left-info-btn">Android下载</div></Popover>
                    </div>
                </div>
            </div>
            <div className="block-five block-two">
                <div className="block-content">
                    <div className='list-five'>
                        {
                           block_5.map((item, i) => 
                            i % 2 === 0 ? <div key={i} className='item'>
                                <div className={`content`}>
                                    {
                                        item.data.map((ele, j) => 
                                        <div key={j} className={`parentItem ${item.data.length !== 0 ? 'parentItemAll' : ''}`}>
                                            <div className='itemTitle'>{ele.sName}</div>
                                            <div className='itemContent'>
                                                {
                                                    ele.data.map((element, index) => 
                                                    <div key={index} dangerouslySetInnerHTML={{__html:  element.sContent}}>
                                                       
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className={`img`}>
                                    <img src={item.sImg}/>
                                </div>
                            </div> : 
                            <div key={i} className='item'>
                                <div className={`img`}>
                                    <img src={item.sImg}/>
                                </div>
                                <div className={`content`}>
                                    {
                                        item.data.map((ele, j) => 
                                        <div key={j} className={`parentItem ${item.data.length !== 0 ? 'parentItemAll' : ''}`}>
                                            <div className='itemTitle'>{ele.sName}</div>
                                            <div className='itemContent'>
                                                {
                                                    ele.data.map((element, index) => 
                                                    <div key={index} dangerouslySetInnerHTML={{__html:  element.sContent}}>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </main>
    )
}
export default Version_2