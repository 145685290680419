import { useEffect, useState } from 'react'
import './index.less'
import { 
    geTokenByUserInfo
} from '../../utils/api'
import common, { getQueryString } from '../../utils/common'
import Header from '../../components/header/index'
import Footer from '../../components/footer/index'
import Version_1 from './version_1'
import Version_2 from './version_2'
import Version_3 from './version_3'
import LoginPage from './loginPage'
function Dongmi() {
    const [version, setVersion] = useState(1)
    const [sType, setType] = useState(getQueryString('login_page') === '2' ? 2 : 1)
    const changeNode = () => {
        switch (version) {
            case 1:
                return <Version_1 onLogin={onLogin}/>
            case 9:
                return <Version_2 onLogin={onLogin}/>
            case 8:
                return <Version_3 onLogin={onLogin}/>
            default:
                break;
        }
    }
    const checkToken = (value) => {
        const data = {
            token: JSON.parse(localStorage.getItem("rongzheng_dongmi_token")).token
        }
        geTokenByUserInfo(data).then((res)=>{
            if(res){
                if(getQueryString("next_url")) {
                    window.location.href = getQueryString("next_url")
                    return
                }
                window.location.href = common  + '/dongmi/'
            } else {
                setType(value)
            }
        })
    }
    const onLogin = (data) => {
        if(data === 2) {
            if(localStorage.getItem("rongzheng_dongmi_token")) {
                checkToken(data)
            } else {
                setType(data)
            }
        } else {
            setType(data)
            if(getQueryString("add_url") && getQueryString("add_url") === '1') {
                window.history.pushState(null, '', window.location.origin)
            }
        }
    }
    useEffect(() => {
        if(getQueryString("add_url") && getQueryString("add_url") === '1') {
            setType(2)
        }
        // document.addEventListener('visibilitychange',function(){
        //     if(document.visibilityState==='hidden'){
        //         console.log("选项卡切换");
        //     } else {
        //         console.log("选项卡选中");
        //     }
        // });
    }, [])
    return (
        <main >
            {
                sType === 1 ? 
                <div className="main">
                    <Header onLogin={onLogin} version={version} setVersion={setVersion}/>
                    <div className="content">
                        {changeNode()}
                    </div>
                    <Footer onLogin={onLogin} version={version}/>
                </div> : 
                <LoginPage onLogin={onLogin}/>
            }
        </main>
    )
}
export default Dongmi
