import { useState } from 'react'
import four_1 from '../../assets/header/four_1.svg'
import four_2 from '../../assets/header/four_2.svg'
import four_3 from '../../assets/header/four_3.svg'
import four_4 from '../../assets/header/four_4.svg'
import icon_1 from '../../assets/ipo/icon_1.svg'
import icon_2 from '../../assets/ipo/icon_2.svg'
import icon_3 from '../../assets/ipo/icon_3.svg'
import icon_4 from '../../assets/ipo/icon_4.svg'
import block_img_4 from '../../assets/header/block_4.png'
import icon_ipo_three from '../../assets/banner/icon_ipo_three.png'
import './version_3.less'
const block_4 = [
    { sName: '速度快  成本低  风险小', sContent: '一键生成股东名册分析报告，本地安装客户端，支持断网操作', sImg: four_1},
    { sName: '法规全  数据精  功能好', sContent: '精准严格的法规法条拆解，为企业合规提供高质量数据', sImg: four_2},
    { sName: '公告广  强辅助  体验好', sContent: '覆盖三市全网公告，一键调取所需。完善的公告披露指引，为合规信披保驾护航', sImg: four_3},
    { sName: '选择多  可信赖  体验好', sContent: '产品根据不同需求为客户提供web端、PC端等个性化平台，更加方便高效地为客户提供服务', sImg: four_4}
]
const block_2 = [
    { sName: '工作高效便捷', sContent: '致力于为拟上市公司提供合规信息化SaaS产品及大数据服务，助力董办合规工作互联网+，提升工作效率', sImg: icon_4},
    { sName: '数据展示直观', sContent: '可视化数据能够用一些简短的图形就能体现那些复杂信息，甚至单个图形也能做到，在工作中更加方便地对数据了解和使用', sImg: icon_1},
    { sName: '数据处理多维度', sContent: '多维度处理数据，满足董办多场景多需求，为董办工作合规高效打下坚实基础', sImg: icon_2},
    { sName: '多场景智能预警', sContent: '根据用户预先设置的预警条件，智能判别数据，并将符合条件的信息，第一时间通过多渠道告知用户，方便用户及时知晓', sImg: icon_3}
]
const block_5 = [
    { sImg: "https://data.wedongmi.com/xinpi/official_website/png/ipo_2_01.png", sName: 'IPO问答库', data: [
        {sContent: '30000+条海量问答，覆盖1000+上市公司问答数据'},
        {sContent: '100+问题类型，4大监管主体全覆盖'},
        {sContent: 'IPO上市进程各阶段问答清晰展示'}
    ]},
    { sImg: "https://data.wedongmi.com/xinpi/official_website/png/ipo_2_02.png", sName: 'IPO案例库', data: [
        {sContent: '3000+上市公司海量IPO案例'},
        {sContent: '支持各板块、各行业、进程状态智能查询'},
        {sContent: '支持近1年新增案例统计、违规类型、监管类型等多维度数据统计'}
    ]},
    { sImg: "https://data.wedongmi.com/xinpi/official_website/png/ipo_2_03.png", sName: 'IPO要点图谱', data: [
        {sContent: '三大交易所五大板块上市条件全面梳理'},
        {sContent: '上市前期准备、企业尽调整改、上市辅导及核准制、注册制发行全流程解读'},
        {sContent: '上市审核要点和相关法规解读'}
    ]},
    { sImg: "https://data.wedongmi.com/xinpi/official_website/png/ipo_2_04.png", sName: 'IPO法库', data: [
        {sContent: '涵盖24大业务分类， 4000+部法规'},
        {sContent: '支持标题、正文、时效性等复杂查询，支持查看法规历史沿革、修订对比'},
        {sContent: '法规及时更新，适用范围清晰追溯'}
    ]}
]
function Version_3(props) {
    const { onLogin } = props
    return (
        <main className="version_3">
            <div className="block-banner">
                <div className="block-content">
                    <div className="banner-back">
                        
                    </div>
                </div>
                <div className="login-content">
                    <div className="left-info">
                        <div className="left-info-title">董蜜IPO版</div>
                        <div className="left-info-title-small">服务于拟上市公司的AI智能办公平台，助力公司顺利上市</div>
                        {/* <div onClick={apply} className="left-info-btn">申请试用</div> */}
                    </div>
                </div>
            </div>
            <div className="block-two">
                <div className="block-content">
                    <div className="title">产品优势</div>
                    <div className='list'>
                        {
                            block_2.map((item, i)=>
                            <div key={i} className="item">
                                <div>
                                    <img src={item.sImg}/>
                                    <div>{item.sName}</div>
                                </div>
                                <div>{item.sContent}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="block-one">
                <div className="block-content">
                    <div className="banner-back">
                        <div className="title">功能概览</div>
                    </div>
                </div>
            </div>
            <div className="block-five block-two">
                <div className="block-content">
                    <div className="title">核心功能</div>
                    <div className='list-five'>
                        {
                           block_5.map((item, i) => 
                            i % 2 === 0 ? <div key={i} className='item'>
                                <div className={`content`}>
                                    <div className='itemTitle'>{item.sName}</div>
                                    <div className='itemContent'>
                                        {
                                            item.data.map((ele, j) => 
                                            <div key={j}>
                                                {ele.sContent}
                                            </div>
                                        )}
                                    </div>
                                    <div onClick={()=>onLogin(2)} className='try'>立即体验</div>
                                </div>
                                <div className={`img`}>
                                    <img src={item.sImg}/>
                                </div>
                            </div> : 
                            <div key={i} className='item'>
                                <div className={`img`}>
                                    <img src={item.sImg}/>
                                </div>
                                <div className={`content`}>
                                    <div className='itemTitle'>{item.sName}</div>
                                    <div className='itemContent'>
                                        {
                                            item.data.map((ele, j) => 
                                            <div key={j}>
                                                {ele.sContent}
                                            </div>
                                        )}
                                    </div>
                                    <div onClick={()=>onLogin(2)} className='try'>立即体验</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="block-four">
                <div className="block-content">
                    <div className="title">技术创新</div>
                    <div className="tip">致力于为上市公司、非上市公司提供专业、安全、合规的信息化SaaS产品及合规诚信大数据服务</div>
                    <div className="list">
                        {
                            block_4.map((item, i)=>
                            <div className="list-item" key={i}>
                                <img src={item.sImg}/>
                                <div className="list-item-content">
                                    <div>{item.sName}</div>
                                    <div>{item.sContent}</div>
                                </div>
                            </div>
                        )}
                    </div>
                    <img className="img" src={block_img_4}/>
                </div>
            </div>
        </main>
    )
}
export default Version_3