import { useEffect } from 'react'
import { Popover } from 'antd'
import './index.less'
function Header(props) {
    const { onLogin, downList, version, setVersion } = props
    const getVersion = (key) => {
        setVersion(key)
    }
    return (
        <main className="header">
            <div className="nav-top">
                <nav className="nav">
                    <img src={"https://data.wedongmi.com/xinpi/official_website/png/logo.png"}/>
                    <div className='nav-menu'>
                        <ul>
                            <li
                                className={"nav-title" + ' ' +(version === 1 ? 'nav-title-active' : '')}
                                onClick={() => getVersion(1)}
                            >
                                董蜜完整版
                                <span></span>
                            </li>
                            <li
                                className={"nav-title" + ' ' +(version === 8 ? 'nav-title-active' : '')}
                                onClick={() => getVersion(8)}
                            >
                                董蜜IPO版
                                <span></span>
                            </li>
                            <li
                                className={"nav-title" + ' ' +(version === 9 ? 'nav-title-active' : '')}
                                onClick={() => getVersion(9)}
                            >
                                董蜜移动端
                                <span></span>
                            </li>
                        </ul>
                        <div onClick={()=>onLogin(2)} className='nav-login'>立即登录</div>
                    </div>
                </nav>
            </div>
        </main>
    )
}
export default Header