import './index.less'
import moment from 'moment'
function Footer(props) {
    const { version, onLogin } = props
    const goPage = (url) => {
        window.history.pushState(null, '', window.location.origin + '?add_url=1&next_url=' + encodeURIComponent(url))
        onLogin(2)
    }
    return (
        <footer>
            <nav style={version === 9 ? {background: "#F8FAFD"} : {}} className="footer">
                <div className="footer-content">
                    <div className="footer-left">
                        <div>
                            {/* <ul className="footer-ul">
                                <li className="footer-title">使用文档</li>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://idtcdn-1309770014.oss-cn-shanghai.aliyuncs.com/rz/office/%E6%99%BA%E5%BA%93%E5%89%8D%E5%8F%B0%E4%BA%A7%E5%93%81%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8CV1.0.pdf"
                                        className="footer-item"
                                    >
                                        董蜜前台产品使用手册 
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://idtcdn-1309770014.oss-cn-shanghai.aliyuncs.com/rz/office/%E6%99%BA%E5%BA%93PC%E7%AB%AF%E4%BA%A7%E5%93%81%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8CV1.0.pdf"
                                        className="footer-item"
                                    >
                                        董蜜PC端产品使用手册
                                    </a>
                                </li>
                            </ul> */}
                            <ul className="footer-ul">
                                <li className="footer-title">联系我们</li>
                                <li className="footer-item">
                                    邮箱：vincenjiang@dengtacj.com
                                </li>
                                <li className="footer-item">电话：+86 15802734159</li>
                                <li className="footer-item">地址：武汉市洪山区光谷大道光谷世贸中心</li>
                            </ul>
                            <ul className="footer-ul">
                                <li className="footer-title">热门模块</li>
                                <li>
                                    <a
                                        onClick={()=>goPage(window.location.origin + "/dongmi/#/host/dataAnalysis/marketAnalysis/search")}
                                        // target="_blank"
                                        // rel="noreferrer"
                                        // href="https://ipo.wedongmi.com"
                                        className="footer-item"
                                    >
                                        上市公司市值检索
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={()=>goPage(window.location.origin + "/dongmi/#/host/dataAnalysis/financial/search")}
                                        // target="_blank"
                                        // rel="noreferrer"
                                        // href="https://th.wedongmi.com"
                                        className="footer-item"
                                    >
                                        上市公司财务数据检索
                                    </a>
                                </li>
                                <li>
                                    <a
                                        onClick={()=>goPage(window.location.origin + "/dongmi/#/host/dataAnalysis/download/analysis")}
                                        // target="_blank"
                                        // rel="noreferrer"
                                        // href="https://th.wedongmi.com"
                                        className="footer-item"
                                    >
                                        股东名册与公告复核系统
                                    </a>
                                </li>
                            </ul>
                            <ul className="footer-ul">
                                <li className="footer-title">快速入口</li>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={window.location.origin + "/dongmi/#/help/detail?pId=1"}
                                        className="footer-item"
                                    >
                                        董蜜使用手册
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://api.wedongmi.com/web/"
                                        className="footer-item"
                                    >
                                        董蜜三大库平台
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://api.wedongmi.com/apidoc/"
                                        className="footer-item"
                                    >
                                        董蜜API开放平台
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer_code">
                        <ul className="footer-ul">
                            <li className="footer-title footer-center">
                                <img src={"https://data.wedongmi.com/rz/app/download/dongmi-100020110-publish.apk.svg"}/>
                            </li>
                            <li className="footer-center">
                                董蜜App 
                            </li>
                        </ul>
                        {/* <ul className="footer-ul">
                            <li className="footer-title footer-center">
                                <img src={ios}/>
                            </li>
                            <li className="footer-center">
                                董蜜App-iOS 
                            </li>
                        </ul>
                        <ul className="footer-ul">
                            <li className="footer-title footer-center">
                                <img src={publicImg}/>
                            </li>
                            <li className="footer-center">
                                董蜜公众号
                            </li>
                        </ul> */}
                        
                    </div>
                </div>
                <div className='beian'>
                    <span>
                        <img src={"https://idtcdn-1309770014.oss-cn-shanghai.aliyuncs.com/rz/office/%E6%B2%AA%E5%85%AC%E7%BD%91%E5%AE%89%E5%A4%87.png"} />
                        <a 
                            target="_blank" 
                            href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010502006619"
                        >
                            鄂公网安备 42011102003768号
                        </a>
                    </span>
                    |
                    <span>
                        <a 
                            target="_blank" 
                            href="https://beian.miit.gov.cn/#/Integrated/index"
                        >
                            浙ICP备19002684号-1
                        </a>
                    </span>
                    <span>Copyright © {moment().format("YYYY")} 宁波灯之芯科技有限公司 All rights reserved </span>
                </div>
            </nav>
        </footer>
    )
}
export default Footer