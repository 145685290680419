import axios from 'axios'
import { isEnv } from './common'
const hostC = isEnv() === 1 ? `${window.location.origin}/gateway/json`: `${window.location.origin}/gateway/json`
export async function applyForRial(params) {
    return axios.post(`${hostC}/userx/applyForRial`, JSON.stringify({req: params}), {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            'X-Token': 'dongmi'
        }
    });
}
export async function getOfflinePCProductList(params) {
    return axios.post(`${hostC}/userx/getOfflinePCProductList`, JSON.stringify({req: params}), {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            'X-Token': 'dongmi'
        }
    });
}

export async function getUpdateTip(params) {
    return axios.post(`${hostC}/userx/getUpdateTip`, JSON.stringify({req: {...params, stXPUserInfo: {from: 'manage'}}}), {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            'X-Token': 'dongmi'
        }
    });
}

export async function closeUpdateTip(params) {
    return axios.post(`${hostC}/userx/closeUpdateTip`, JSON.stringify({req: {...params, stXPUserInfo: {from: 'manage'}}}), {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            'X-Token': 'dongmi'
        }
    });
}