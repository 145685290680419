import Dongmi from './page/dongmi/index'
import './App.less'
function App() {
    return (
        <main className="app">
           <Dongmi />
        </main>
    )
}
export default App
